import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap/';

import Teacher from './components/Teacher';
import TeacherNew from './components/TeacherNew';
import TeacherForm from './components/TeacherForm';
import Home from './components/Home';
import Auditors from './components/Auditors';
import Navigation from './components/Navigation';
import Donation from './components/Donation';
import Footer from './components/Footer';
import ReviewForm from './components/ReviewForm';
import WhoWeAre from './components/WhoWeAre';
import summaryPage from './components/summaryPage'


class App extends React.Component {

  render() {
    document.title = "Apple Runway"

    return (
      
        
        <div className="body">
          <BrowserRouter>
            <Switch>
              <Route path="/auditors" component={() => {
                window.location.href = 'https://stage-apple-runway.auth.us-east-1.amazoncognito.com/login?response_type=code&client_id=2d76m2s05ffo0v1lbtb9jssfho&redirect_uri=https://stage-applerunway.org/auditors-logged-in';
                return null;
              }} />
              <Route path="/teacherNew" component={() => {
                window.location.href = 'https://stage-apple-runway.auth.us-east-1.amazoncognito.com/login?response_type=code&client_id=2d76m2s05ffo0v1lbtb9jssfho&redirect_uri=https://stage-applerunway.org/teachers-logged-in';
                
                return null;
              }} />
              <Route path="/" component={Home} exact />
              <Route path="/auditors-logged-in" component={Auditors} />
              <Route path="/donation" component={Donation} />
              <Route path="/teachers-logged-in" component={TeacherNew} />
              <Route path="/teacherForm" component={TeacherForm} />
              <Route path="/reviewForm" component={ReviewForm} />
              <Route path="/whoweare" component={WhoWeAre} />
              <Route path="/summaryPage" component={summaryPage} />
              <Route path="/home" component={Home} />
            </Switch>            
          </BrowserRouter>
        </div>
        
      
    );
  }
}

export default App