var jwt_decode = require('jwt-decode');
class authentication{
    static authentication(code, redirect_uri){

        var url = new URL('https://stage-apple-runway.auth.us-east-1.amazoncognito.com/oauth2/token')
        var paramsForURL = {grant_type:"authorization_code", code: code, client_id: "2d76m2s05ffo0v1lbtb9jssfho", redirect_uri: redirect_uri } 

        url.search = new URLSearchParams(paramsForURL).toString();
        
        var request = new XMLHttpRequest();
        request.open('POST', url, false);  // `false` makes the request synchronous
        request.setRequestHeader("Content-Type",'application/x-www-form-urlencoded')
        request.send(null);

        var json = JSON.parse(request.responseText)
        return json
    }
    static get_refresh_token(token){
        var url = new URL('https://odot2imfp3.execute-api.us-east-1.amazonaws.com/apple-runway/authentication')
        var paramsForURL = {refresh_token: token}
        url.search = new URLSearchParams(paramsForURL).toString();
        var request = new XMLHttpRequest();
        request.open('POST', url, false);  // `false` makes the request synchronous
        request.setRequestHeader("Content-Type", "application/x-www-form-urlencoded")
        request.send(null);

        var json = JSON.parse(request.responseText)
        if(json['message'] == "Internal Server Error")
        {
            return "No Token Provided"
        }
        var accessToken = json["body"]["AuthenticationResult"]["AccessToken"]
        return accessToken


    }
    static decode_tokens(token){
        try{
            return jwt_decode(token)
        }
        catch(err){
            return "Invalid Token"
        }
        
    }
}

export default authentication