import React, { Component } from 'react';
import Navigation from './Navigation';
import Footer from './Footer';
import { NavLink } from 'react-router-dom';
import { Card, Button, Jumbotron, Col, Container, Row, Image } from 'react-bootstrap';
import cardImg1 from '../iAssets/materialWavingPerson.svg'
import cardImg2 from '../iAssets/materialLightBulb.svg'
import cardImg3 from '../iAssets/heart.svg'
import whiteDollar from '../iAssets/filledHeart.svg'
import cardImg4 from '../iAssets/support.svg'
import jumboBack from '../iAssets/pencils.jpg'
import suppliesImg from '../iAssets/pencil-ruler.svg'
import form from '../iAssets/list.svg'
import approved from '../iAssets/grading.svg'
import getMoney from '../iAssets/grad.svg'
import ideaBack from '../iAssets/floating-cogs.svg'

//<h1 style={{ fontSize: '700%', color: '#3385ff', fontFamily: 'Trebuchet MS', fontWeight: '500' }}>Welcome</h1>
class home extends React.Component {
    render() {
        return (
            
            <div style={{ backgroundColor: '#ECEFF1', paddingBottom: "0px", minHeight: "100vh" }}>
                <Navigation />
                <Jumbotron style={{ backgroundColor: '#0090ea', backgroundImage: 'url(' + jumboBack + ')', backgroundSize: '100% 100%', backgroundPositionX: '50%', backgroundPositionY: '100%', backgroundRepeat: 'no-repeat', paddingBottom: '40%', marginTop: '-16%' }} />


                {/* 
                <div style={{ backgroundColor: '#388E3C', marginTop: '-30px', paddingTop: '2%' }}>
                    <Row>
                        <Col md={{ span: 3, offset: 2 }} style={{textAlign:'right'}}>
                            
                        </Col>
                        <Col md={{ span: 3, offset: 2 }} style={{textAlign:'left'}}>
                            
                        </Col>
                    </Row>                    
                </div>
                */}

                <Container>
                    <Row xs={1} md={2} lg={4}>
                        <Col>
                            <Card style={{ height: '400px' }}>
                                <Image src={cardImg1} style={{ width: '50%', height: '40%', alignSelf: 'center' }} />
                                <Card.Body>
                                    <Row style={{ justifyContent: 'center' }}>
                                        <Card.Title>Who We Are</Card.Title>
                                    </Row>
                                    <Row style={{padding: '0 5% 0 5%'}}>
                                        <Card.Text>We're a small group passionate about lowering the barriers to a good education</Card.Text>
                                    </Row>
                                    <Row style={{ justifyContent: 'center' }}>
                                        <NavLink to="/whoweare"><Button variant="primary" style={{ backgroundColor: '#0091EA' }}>Learn more</Button></NavLink>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card style={{ height: '400px' }}>
                                <Image src={cardImg2} style={{ width: '50%', height: '40%', alignSelf: 'center' }} />
                                <Card.Body>
                                    <Row style={{ justifyContent: 'center' }}><Card.Title>Our Idea</Card.Title></Row>
                                    <Row style={{padding: '0 5% 0 5%'}}><Card.Text>Our goal is to relieve teachers of financial burdens distracting them from what they do best</Card.Text></Row>
                                    <Row style={{ justifyContent: 'center' }}><a href="#ourIdeaSection"><Button variant="primary" style={{ backgroundColor: '#0091EA' }}>Learn more</Button></a></Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card style={{ height: '400px' }}>
                                <Image src={cardImg3} style={{ width: '50%', height: '40%', alignSelf: 'center' }} />
                                <Card.Body>
                                    <Row style={{ justifyContent: 'center' }}><Card.Title>Donate</Card.Title></Row>
                                    <Row style={{padding: '0 5% 0 5%'}}><Card.Text>Help teachers across the country provide students with quality educational resources</Card.Text></Row>
                                    <Row style={{ justifyContent: 'center' }}><a href="https://www.paypal.com/donate/?hosted_button_id=6UATTZD4RSFDJ" target="_blank"><Button variant="primary" style={{ backgroundColor: '#0091EA' }}>Donate</Button></a></Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card style={{ height: '400px' }}>
                                <Image src={cardImg4} style={{ width: '50%', height: '40%', alignSelf: 'center' }} />
                                <Card.Body>
                                    <Row style={{ justifyContent: 'center' }}><Card.Title>Get Aid</Card.Title></Row>
                                    <Row style={{padding: '0 5% 0 5%'}}><Card.Text>Need help getting your students the books and other resources they need to learn?</Card.Text></Row>
                                    <Row style={{ justifyContent: 'center' }}><NavLink to="/teachers" ><Button variant="primary" style={{ backgroundColor: '#0091EA' }}>Log in or Sign up</Button></NavLink></Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <a name="ourIdeaSection">
                    <div>
                        <Jumbotron fluid style={{backgroundImage: 'url(' + ideaBack + ')', backgroundSize: 'auto auto', backgroundColor: '#38a0ed', padding: '5% 10% 5% 10%', marginBottom:'0%', color: 'white' }}>
                            <h1 style={{ fontWeight: '700' }}>Our Idea</h1>
                            <h4>Apple Runway is a non-profit organization with a mission to improve the quality of life and promote a fulfilling educational experience for students and teachers</h4>                        
                        </Jumbotron>
                    </div>
                </a>

                <div>
                    <Container fluid style={{ backgroundColor: '#0083dc', padding: '5% 10% 5% 10%', color: 'white' }}>
                        <h1 style={{ fontWeight: '700', textAlign:'center' }}> Where does your money go?</h1>
                        <br></br>
                        <Row xs={1} md={2} lg={4} style={{justifyContent: 'center', textAlign: 'center'}}>
                            <Col>
                                <Image src={whiteDollar} style={{ width: '80%', height: '80%', alignSelf: 'center', paddingBottom: '5px' }} />

                                <h3>1. We receive your donation</h3>
                            </Col>
                            <Col>
                                <Image src={form} style={{ width: '80%', height: '80%', alignSelf: 'center', paddingBottom: '5px' }} />
                                <h3>2. Teachers request aid</h3>
                            </Col>
                            <Col>
                                <Image src={approved} style={{ width: '80%', height: '80%', alignSelf: 'center', paddingBottom: '5px' }} />
                                <h3>3. Teachers approved for aid</h3>
                            </Col>
                            <Col>
                                <Image src={getMoney} style={{ width: '80%', height: '80%', alignSelf: 'center', paddingBottom: '5px' }} />
                                <h3>4. Teachers receive aid</h3>
                            </Col>                            
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        );
    }
}

export default home

/*
Color Scheme Primary
9:#004399
8:#0060b8
7:#0071ca
6:#0083dc
5:#0090ea
4:#38a0ed
3:#5eb0ef
2:#8cc7f4
1:#b9dcf8
50:#e2f1fc
*/