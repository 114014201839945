import React from 'react';
import { NavLink } from 'react-router-dom';
import { Nav, Navbar, Container, Image, NavDropdown } from 'react-bootstrap';
import cardImg4 from '../iAssets/AR_plain_no_letters.png'

const Navigation = () => {
   
   return (
      <Navbar collapseOnSelect bg="#0091EA" style={{backgroundColor: '#0071ca', display:'flex'}}>
     {/*<Navbar collapseOnSelect bg="#0091EA" style={{backgroundColor: '#0071ca', paddingRight:'2%', paddingLeft:'2%' }}> */}
      
         <Navbar.Brand href="/"> <Image fluid src={cardImg4} style={{height: '40px'}}/></Navbar.Brand>
         <Navbar.Brand href="/" style={{color: 'white'}}>Apple Runway</Navbar.Brand>
         <Navbar.Toggle aria-controls="responsive-navbar-nav" />
         <Navbar.Collapse className="justify-content-end" style={{backgroundColor: '#0071ca', lineHeight:'0'}}>         
            
            <Nav style={{backgroundColor: '#0071ca', textAlign:'center'}}>
               <Nav.Link href="https://www.paypal.com/donate?hosted_button_id=6UATTZD4RSFDJ" target="_blank" style={{color: 'white'}}>Donate</Nav.Link>                                                  
               <Nav.Link href="/auditors" style={{color: 'white'}}>Audit</Nav.Link>
               <Nav.Link href="/teachers" style={{color: 'white'}}>Request</Nav.Link>          
            </Nav>
            
         </Navbar.Collapse>
      
      </Navbar>

      
      
      
      
    );
   
}
export default Navigation
