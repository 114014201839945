import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Card, Button, Jumbotron, Col, Container, Row, Image } from 'react-bootstrap';
import cardImg1 from '../iAssets/users.svg'
import cardImg2 from '../iAssets/lightbulb.svg'
import cardImg3 from '../iAssets/hand-holding-usd.svg'
import cardImg4 from '../iAssets/chalkboard-teacher.svg'
import jumboBack from '../iAssets/jumboBack.jpg'
import suppliesImg from '../iAssets/pencil-ruler.svg'


//<h1 style={{ fontSize: '700%', color: '#3385ff', fontFamily: 'Trebuchet MS', fontWeight: '500' }}>Welcome</h1>
class summaryPage extends React.Component {
    render() {
        return (

                <Container fluid style={{ minHeight: "100vh"}}>
                    <h1>Summary</h1>
                </Container>
            
        );
    }
}

export default summaryPage
