import React, { Component } from 'react';
import { Container, Jumbotron, Form, Card, Col, Row, InputGroup } from 'react-bootstrap';
import { PayPalButton } from "react-paypal-button-v2";

class Donation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            enteredAmount: "1",
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange = (e) => {
        let enteredAmount = e.target.value
        this.setState({ enteredAmount })
    }

    create_donation(amount, name, email) {
        var jsonBlob = { donation_amount: amount, donor_name: name, email: email }

        let url = new URL('https://odot2imfp3.execute-api.us-east-1.amazonaws.com/apple-runway/createDonationEntry')
        let request = new XMLHttpRequest();
        request.open('POST', url, false);  // `false` makes the request synchronous
        request.send(JSON.stringify(jsonBlob));
        let json = JSON.parse(request.responseText)
        if (json['message'] == "Internal Server Error") {
            return "No Token Provided"
        }
        return json
    }
    //Render the paypal donation button from saved buttons
    render() {
        return (
            <Container fluid style={{ backgroundColor: '#0090ea', padding: '10% 0 10% 0', minHeight: "60vh" }} >
                <Row>

                    <Col md={{ span: 4, offset: 4 }}>
                        <Card>
                            <Card.Header><Card.Title style={{ alignContent: 'center' }}>Pay Securely With Paypal</Card.Title></Card.Header>
                            <Card.Body>
                                <p>Help teachers today! Donate to help them afford supplies for their students!</p>


                                <Form style={{ width: '40%' }}>
                                    <Form.Group>
                                        <Form.Label>Amount: </Form.Label>
                                        <Form.Control name="enteredAmount" onChange={this.handleChange} size="sm" type="number" min="1" placeholder="1" />
                                        <Form.Text className="text-muted">
                                            Please enter a dollar amount
                                        </Form.Text>
                                    </Form.Group>
                                </Form>



                                <PayPalButton
                                    amount={this.state.enteredAmount}
                                    shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                                    onSuccess={(details, data) => {
                                        this.create_donation(this.state.enteredAmount, details.payer.name.given_name, details.payer.email_address)
                                        alert("Transaction completed");

                                        // OPTIONAL: Call your server to save the transaction
                                        return fetch("/paypal-transaction-complete", {
                                            method: "post",
                                            body: JSON.stringify({
                                                orderId: data.orderID
                                            })
                                        });
                                    }}
                                    options={{
                                        // test client id-> clientId: "ATvzmfzMj2VYGTh_WTmzVDGS7lGmKaHiznzc31_rOsHmJP_NAVj9jCJLrvDNatbS_mSZYpuzcFnBuEqA"
                                        clientId: "AQ-Y4QH27Nt8HrJSzkq32oG0P3Q99UcP_GfS84D-RV-ZuaiP9uFDV6iZqAdRwGaU66TkJIwyj5kEeZAf"
                                    }}
                                />


                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

            </Container >

        );
    }
}
//<p><b>Please note, our 501c3 status application is still under review. While we expect no issues in being approved for the status, we cannot guarantee that donations can be claimed as tax deductible until our approval is granted.</b></p>
//<p><b>If you would like to be notified upon our approval, please email admin@thecommunitydesk.org</b></p>
export default Donation
