import React, { Component } from 'react';
import {Col, Container, Row, Image, Nav } from 'react-bootstrap';
import cardImg4 from '../iAssets/WaltWho.png'
import jakeImg from '../iAssets/jakewho2.jpg'
import akhilImg from '../iAssets/akhilwho.jpg'
import sriramImg from '../iAssets/sriramwho.jpg'
import ashimImg from '../iAssets/ashimwho2.jpg'
import cindyImg from '../iAssets/cindyWho2.jpg'
import linkdinImg from '../iAssets/LI-In-Bug.png'

//<h1 style={{ fontSize: '700%', color: '#2e7d32', fontFamily: 'Trebuchet MS', fontWeight: '500' }}>Welcome</h1>

class people extends React.Component {
    constructor(props) {
        super(props)
        this.headShots = this.headShots.bind(this)
    }


    headShots() {
        return (
            <>
                <Col>
                    <Image src={cardImg4} style={{ borderRadius: "50%", width: '50%', height: '40%', alignSelf: 'center' }} />
                    <h1 style={{ fontSize: '20px', fontFamily: 'Trebuchet MS', fontWeight: '600' }}>Name Name</h1>
                    <h2 style={{ fontSize: '15px', color: '#2e7d32', fontFamily: 'Trebuchet MS', fontWeight: '600'}}>What my role is</h2>
                    <h3 style={{ fontSize: '18px', fontFamily: 'Trebuchet MS', fontWeight: '300' }}>My background is in x field and I specialize in x</h3>
                    <Image src={cardImg4} style={{ width: '5%', height: '5%', alignSelf: 'center' }} />
                </Col>
            </>
        );
    }

    render() {
        return (

            <Container style={{ minHeight: "100vh", textAlign: "center", paddingTop: "5%", }}>
                <Row style={{ justifyContent: 'center', paddingBottom: '5%' }}>
                    <h1>Meet the Team </h1>
                </Row>
                <Row xs={1} md={2} lg={3} style={{ paddingBottom: '5%' }}>
                    <Col>
                        <Image src={jakeImg} style={{ borderRadius: "50%", width: '150px', height: '150px', alignSelf: 'center' }} />
                        <h1 style={{ fontSize: '20px', fontFamily: 'Trebuchet MS', fontWeight: '600' }}>Jake Sedlacek CNP</h1>
                        <h2 style={{ fontSize: '15px', color: '#2e7d32', fontFamily: 'Trebuchet MS', fontWeight: '600'}}>Board - Strategy & Operations</h2>
                        <h3 style={{ fontSize: '18px', fontFamily: 'Trebuchet MS', fontWeight: '300' }}>Product manager with a focus on scalable design and remote first solutions</h3>
                        <a href="https://www.linkedin.com/in/jakesedlacek/"><Image src={linkdinImg} style={{ width: '5%', height: '5%', alignSelf: 'center' }} /></a>
                    </Col>
                    <Col>
                        <Image src={sriramImg} style={{ borderRadius: "50%", width: '150px', height: '150px', alignSelf: 'center' }} />
                        <h1 style={{ fontSize: '20px', fontFamily: 'Trebuchet MS', fontWeight: '600' }}>Sriram Rajaram</h1>
                        <h2 style={{ fontSize: '15px', color: '#2e7d32', fontFamily: 'Trebuchet MS', fontWeight: '600'}}>Board - Development & Architecture</h2>
                        <h3 style={{ fontSize: '18px', fontFamily: 'Trebuchet MS', fontWeight: '300' }}>Software Engineer with a expertise in building stable, scalable products</h3>
                        <a href="https://www.linkedin.com/in/sriram-rajaram1"><Image src={linkdinImg} style={{ width: '5%', height: '5%', alignSelf: 'center' }} /></a>
                    </Col>
                    <Col>
                        <Image src={akhilImg} style={{ borderRadius: "50%", width: '150px', height: '150px', alignSelf: 'center' }} />
                        <h1 style={{ fontSize: '20px', fontFamily: 'Trebuchet MS', fontWeight: '600' }}>Akhil Sangem</h1>
                        <h2 style={{ fontSize: '15px', color: '#2e7d32', fontFamily: 'Trebuchet MS', fontWeight: '600'}}>Board - UX & Front End Development</h2>
                        <h3 style={{ fontSize: '18px', fontFamily: 'Trebuchet MS', fontWeight: '300' }}>Technical Analyst experienced in driving maintainability and product improvement</h3>
                        <a href="https://www.linkedin.com/in/akhil-sangem"><Image src={linkdinImg} style={{ width: '5%', height: '5%', alignSelf: 'center' }} /></a>
                    </Col>
                </Row>
                <Row><br></br></Row>
                <Row xs={1} md={2} lg={3} style={{ paddingBottom: '5%' }}>
                    <Col>
                        <Image src={cindyImg} style={{ borderRadius: "50%", width: '150px', height: '150px', alignSelf: 'center' }} />
                        <h1 style={{ fontSize: '20px', fontFamily: 'Trebuchet MS', fontWeight: '600' }}>Cindy Christensen</h1>
                        <h2 style={{ fontSize: '15px', color: '#2e7d32', fontFamily: 'Trebuchet MS', fontWeight: '600'}}>Board</h2>
                        <h3 style={{ fontSize: '18px', fontFamily: 'Trebuchet MS', fontWeight: '300' }}>Retired teacher with over 30 years experience from establishing and operating a private preschool to teaching elementary and middle school students</h3>
                        <Image src={linkdinImg} style={{ width: '5%', height: '5%', alignSelf: 'center' }} />
                    </Col>
                    <Col>
                        <Image src={cardImg4} style={{ borderRadius: "50%", width: '150px', height: '150px', alignSelf: 'center' }} />
                        <h1 style={{ fontSize: '20px', fontFamily: 'Trebuchet MS', fontWeight: '600' }}>Ron Schubel</h1>
                        <h2 style={{ fontSize: '15px', color: '#2e7d32', fontFamily: 'Trebuchet MS', fontWeight: '600'}}>Board</h2>
                        <h3 style={{ fontSize: '18px', fontFamily: 'Trebuchet MS', fontWeight: '300' }}></h3>
                        <Image src={linkdinImg} style={{ width: '5%', height: '5%', alignSelf: 'center' }} />
                    </Col>
                    <Col>
                        <Image src={ashimImg} style={{ borderRadius: "50%", width: '150px', height: '150px', alignSelf: 'center' }} />
                        <h1 style={{ fontSize: '20px', fontFamily: 'Trebuchet MS', fontWeight: '600' }}>Ashim Bajwa</h1>
                        <h2 style={{ fontSize: '15px', color: '#2e7d32', fontFamily: 'Trebuchet MS', fontWeight: '600'}}>Board - Strategy & Operations</h2>
                        <h3 style={{ fontSize: '18px', fontFamily: 'Trebuchet MS', fontWeight: '300' }}>US Naval Officer with experience in project Management and resource allocation.</h3>
                        <Nav.Link href="/teachers"><Image src={linkdinImg} style={{ width: '5%', height: '5%', alignSelf: 'center' }} /></Nav.Link>
                    </Col>
                </Row>
            </Container>

        );
    }
}

export default people
