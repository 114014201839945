import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Container, Row, Col, Button, Image, Card, Jumbotron, Figure, Modal, Form, Table } from 'react-bootstrap';
import TeacherForm from './TeacherForm';
import authentication from './authentication'
import cardImg1 from '../iAssets/chalkboard-teacher.svg'

class teacherNew extends Component {
   constructor(props) {
      super(props);
      this.state = {
         teacherInfo: [props.name, props.id, props.email, props.token],
         numSubmits: 0,
         showModal: false,
         showItemModal: false,
         showRequestModal: false,
         items: null,
         reviewReason: null,
         reviewId: null
      }
      this.updateTeacher = this.updateTeacher.bind(this)
      this.editModalEnabled = this.editModalEnabled.bind(this)
      this.itemsModal = this.itemsModal.bind(this)
      this.itemsModalEnabled = this.itemsModalEnabled.bind(this)
      this.requestModal = this.requestModal.bind(this)
      this.requestModalEnabled = this.requestModalEnabled.bind(this)
   }

   tokens = this.auth()


   auth() {
      let search = this.props.location.search;
      let params = new URLSearchParams(search);
      let code = params.get("code");
      if (code == null) {
         return false
      }

      let tokens = authentication.authentication(code, "https://stage-applerunway.org/teachers-logged-in")
      
      if (tokens["error"] != null) {
         return false
      }

      let access_token = authentication.decode_tokens(tokens["access_token"])
      let id_token = authentication.decode_tokens(tokens["id_token"])

      let api_gw_token = authentication.get_refresh_token(tokens["refresh_token"])
      if (api_gw_token === "No Token Provided" || access_token === "Invalid Token") {
         return false
      }
      let finalTokens = { group: access_token['cognito:groups'], api_token: api_gw_token, username: access_token['username'], email: id_token["email"] }
      return finalTokens

   }

   get_teacher_requests() {
      let url = new URL('https://odot2imfp3.execute-api.us-east-1.amazonaws.com/apple-runway/pendingTeacherRequests')
      var paramsForURL = { username: this.tokens.username }
      url.search = new URLSearchParams(paramsForURL).toString();
      let request = new XMLHttpRequest();
      request.open('GET', url, false);  // `false` makes the request synchronous
      request.setRequestHeader("Authorization", "Bearer " + this.tokens.api_token)
      request.setRequestHeader("Content-Type", "application/json;charset=UTF-8")
      request.send();
      let json = JSON.parse(request.responseText)
      if (json['message'] == "Internal Server Error") {
         return "No Token Provided"
      }
      let myList = json
      return myList
   }

   get_teacher_info() {
      let url = new URL('https://odot2imfp3.execute-api.us-east-1.amazonaws.com/apple-runway/getTeacherInfo')
      var paramsForURL = { username: this.tokens.username }
      url.search = new URLSearchParams(paramsForURL).toString();
      let request = new XMLHttpRequest();
      request.open('GET', url, false);  // `false` makes the request synchronous
      request.setRequestHeader("Authorization", "Bearer " + this.tokens.api_token)
      request.setRequestHeader("Content-Type", "application/json;charset=UTF-8")
      request.send();
      let json = JSON.parse(request.responseText)
      if (json['message'] == "Internal Server Error") {
         return "No Token Provided"
      }
      let finalVal = JSON.parse(json["body"])
      return finalVal
   }

   updateTeacher(formSumbit) {
      let count = this.state.numSubmits + 1
      this.setState({
         numSubmits: count
      })
   }

   itemsModal() {
      let show = this.state.showItemModal

      const handleClose = () => this.setState({ showItemModal: false, items: null });
      const handleShow = () => this.setState({ showItemModal: true });

      if (this.state.items != null) {
         return (
            <>

               <Modal show={show} onHide={handleClose} style={{minHeight:'100%'}}>
                  <Modal.Header closeButton>
                     <Modal.Title>Request {this.state.reviewId}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                     <Table striped bordered hover responsive>
                        <thead>                           
                           <th>Item</th>
                           <th>Quantity</th>
                           <th>Cost</th>
                        </thead>
                        <tbody>
                        {
                           this.state.items.map((item) =>
                              //render name, quant, dollars
                              <tr>
                                 <td>{item.name}</td>
                                 <td>{item.quant}</td>
                                 <td>{item.dollars}</td>
                              </tr>                                             
                           )
                        }
                        </tbody>
                     </Table>
                     <p>Reason: {this.state.reviewReason}</p>                     
                  </Modal.Body>
               </Modal>
            </>
         );
      }
      else {
         return (
            <>
               <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                     <Modal.Title>Items Error</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                     <h1> No Items</h1>
                  </Modal.Body>
               </Modal>
            </>
         );
      }

   }

   requestModal() {
      let requestModalShow = this.state.showRequestModal

      const requestModalhandleClose = () => this.setState({ showRequestModal: false});
      const requestModalhandleShow = () => this.setState({ showRequestModal: true });

      return (
         <>
         <Modal show={requestModalShow} onHide={requestModalhandleClose} style={{minHeight:'100%', minWidth:'42vw' }}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
               <TeacherForm name={this.tokens.username} token={this.tokens.api_token} email={this.tokens.email} onSubmit={this.updateTeacher} />
            </Modal.Body>
         </Modal>
         </>
      );

   }

   editModalEnabled() {
      let show = this.state.show
      this.setState({ show: true })
   }

   itemsModalEnabled(review) {
      let show = this.state.showItemModal
      this.setState({ showItemModal: true, items: review.Items, reviewReason: review.reasoning, reviewId: review.requestID})
   }

   requestModalEnabled() {
      let requestModalEnabledshow = this.state.showRequestModal
      this.setState({ showRequestModal: true})
      console.log("Request Modal Enabled: " + this.state.showRequestModal )
   }


   render() {
      if (!this.tokens) {
         return (
            <Jumbotron style={{ backgroundColor: '#ECEFF1', paddingBottom: "0px", minHeight: "100vh" }}>
               <h1>Session Expired Please Re-Login</h1>
               <NavLink to="/teachers" ><Button variant="primary" style={{ backgroundColor: '#0091EA' }}>Log in or Sign up</Button></NavLink>
            </Jumbotron>
         )
      }
      if (!this.tokens.group.includes('teachers')) {
         return (
            <Jumbotron style={{ backgroundColor: '#ECEFF1', paddingBottom: "0px", minHeight: "100vh" }}>
               <h1>You don't have access to this page :/</h1>
               <NavLink to="/Home" ><Button variant="primary" style={{ backgroundColor: '#0091EA' }}>Log in or Sign up</Button></NavLink>
            </Jumbotron>
         )
      }
      
      var allRequests = this.get_teacher_requests()
      var getTeacherInfo = this.get_teacher_info()

      if (allRequests === 'No Token Provided' || getTeacherInfo === 'No Token Provided') {
         return (
            <Container fluid style={{ backgroundColor: '#ECEFF1',  minHeight: "100%"}}>
               <Jumbotron>
               <h1>Token Wasn't Provided :/</h1>
               <NavLink to="/Home" ><Button variant="primary" style={{ backgroundColor: '#0091EA' }}>Log in or Sign up</Button></NavLink>
               </Jumbotron>
            </Container>
         )
      }

      return (
         <Container fluid style={{ backgroundColor: '#ECEFF1',  minHeight: "100%"}}>
            <this.itemsModal />
            <this.requestModal />
            
            <Row sm-1>
               <Col md="2" style={{backgroundColor: '#005fb8', minHeight:'100vh'}}>
                  <Container>
                  <Row style={{ textAlign: 'center' }}>
                     <Col>
                        {/*<Figure.Image fluid src={cardImg1} height={"150px"} width={"150px"} />*/}
                        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0" />
                        <span class="material-symbols-outlined" style={{fontSize:'200px'}}>account_circle</span>
                        <Card.Title>{getTeacherInfo.fname} {getTeacherInfo.lname}</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">{getTeacherInfo.school}</Card.Subtitle>
                     </Col>
                  </Row>
                  <Row>
                     <Col>
                        {/*<Card.Text>username: {this.tokens.username} </Card.Text>*/}
                        {/*<Card.Text>email:  {this.tokens.email}</Card.Text>*/}                              
                        {/* 
                        <Card.Text>School: {getTeacherInfo.school}</Card.Text>
                        <Card.Text>Pending Reviews: </Card.Text>
                        <Card.Text>Completed Reviews: </Card.Text>
                        */}
                     </Col>
                  </Row>
                  <Row>
                     {/*
                        <Col style={{ textAlign: 'center' }}>
                        <Card.Link href="#"><Button onClick={this.editModalEnabled}>Edit</Button></Card.Link>
                        </Col>
                     */
                     }
                  </Row>
                  </Container>
               </Col>
               <Col>
                  <Container className="overflow-auto" style={{ width: '100%', maxHeight: '700px', scrollbarWidth: 'thin' }}>

                     <Card>
                        <Card.Header>
                           <Col style={{textAlign:'left'}}>
                              Your Requests
                           </Col>
                           <Col style={{textAlign:'right'}}>
                              <Button onClick={() => this.requestModalEnabled()}>New Request</Button>
                           </Col>
                        </Card.Header>
                     </Card>


                     <Card>
                     <Row>
                        <Container>
                        <Col style={{textAlign:'left'}}>
                           <h2>Your Requests</h2>
                        </Col>
                        <Col style={{textAlign:'right'}}>
                           <Button onClick={() => this.requestModalEnabled()}>New Request</Button>
                        </Col>
                        </Container>
                     </Row>
                     <Row>
                        <Col>
                        <Table hover responsive>
                           <thead>
                              <th>Request ID</th>
                              <th>Estimated Cost</th>
                              <th>Approval Status</th>
                              <th>Date Submitted</th>
                           </thead>
                           <tbody>
                           {
                                 allRequests.map((review) =>
                                 <tr onClick={() => this.itemsModalEnabled(review)}> 
                                    <td>{review.requestID}</td>
                                    <td>{review.cost}</td>
                                    <td>{review.approval}</td>
                                    <td>{(review.Date).slice(0, 10)}</td>
                                    {/*<td><Button onClick={() => this.itemsModalEnabled(review)}>View Items</Button></td>*/}
                                 </tr>
                                 )
                           }
                           </tbody>
                        </Table>
                        </Col>
                     </Row>
                     </Card>
                  </Container>
               </Col>
            </Row>
         </Container>
      )

   }
}

export default teacherNew

