import React, { Component, useState } from "react";
import authentication from "./authentication";
import { NavLink } from "react-router-dom";
import {
  Form,
  Button,
  Container,
  Row,
  Card,
  Col,
  Modal,
  Jumbotron,
  Table,
} from "react-bootstrap";

class auditors extends React.Component {
  access_token = null;
  api_gw_token = null;

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      submitDisabled: true,
      items: [],
    };
  }

  get_teacher_requests() {
    let url = new URL(
      "https://odot2imfp3.execute-api.us-east-1.amazonaws.com/apple-runway/pendingTeacherRequests"
    );
    let request = new XMLHttpRequest();
    request.open("GET", url, false); // `false` makes the request synchronous
    request.setRequestHeader(
      "Authorization",
      "Bearer " + this.tokens.api_token
    );
    request.send(null);

    let json = JSON.parse(request.responseText);
    if (json["message"] == "Internal Server Error") {
      return "No Token Provided";
    }

    let claimedList = [];
    let unclaimedList = [];
    let completedList = [];

    let allList = [];

    for (var x = 0; x < json.length; x++) {
      if (
        json[x]["claimed"] === this.tokens["username"] &&
        json[x]["approval"] === "PENDING"
      ) {
        claimedList.push(json[x]);
      } else if (
        json[x]["claimed"] === null &&
        json[x]["approval"] === "PENDING"
      ) {
        unclaimedList.push(json[x]);
      } else if (
        json[x]["approval"] != "PENDING" &&
        json[x]["claimed"] === this.tokens["username"]
      ) {
        completedList.push(json[x]);
      }

      allList.push(json[x]);
    }

    return [claimedList, unclaimedList, completedList, allList];
  }

  tokens = this.auth();

  auth() {
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    let code = params.get("code");
    if (code == null) {
      return false;
    }

    let tokens = authentication.authentication(
      code,
      "https://stage-applerunway.org/auditors-logged-in"
    );
    //let tokens = authentication.authentication(code, "localhost:3000/auditors-logged-in")
    if (tokens["error"] != null) {
      return false;
    }

    let access_token = authentication.decode_tokens(tokens["access_token"]);

    let api_gw_token = authentication.get_refresh_token(
      tokens["refresh_token"]
    );
    if (
      api_gw_token === "No Token Provided" ||
      access_token === "Invalid Token"
    ) {
      return false;
    }
    let finalTokens = {
      group: access_token["cognito:groups"],
      api_token: api_gw_token,
      username: access_token["username"],
    };
    return finalTokens;
  }

  openModalWithItem(item) {
    let itemList = [];
    item.Items.forEach((element) => {
      itemList.push(element.name);
    });
    this.setState({
      show: true,
      showReview: false,
      showApproved: false,
      status: item.approval,
      requestId: item.requestID,
      cost: item.cost,
      reason: item.reasoning,
      items: item.Items,
      teacherEmail: item.teacherEmail,
      teacherName: item.teacherName,
    });
    //this.setState({items: item.Items})
  }

  openReviewModalWithItem(item) {
    let itemList = [];
    item.Items.forEach((element) => {
      itemList.push(element.name);
    });
    this.setState({
      show: false,
      showReview: true,
      showApproved: false,
      status: item.approval,
      requestId: item.requestID,
      cost: item.cost,
      reason: item.reasoning,
      items: item.Items,
      teacherEmail: item.teacherEmail,
      teacherName: item.teacherName,
    });
  }

  openCompleteModalWithItem(item) {
    let itemList = [];
    item.Items.forEach((element) => {
      itemList.push(element.name);
    });
    this.setState({
      show: false,
      showReview: false,
      showApproved: true,
      status: item.approval,
      requestId: item.requestID,
      cost: item.cost,
      reason: item.reasoning,
      items: item.Items,
      teacherEmail: item.teacherEmail,
      teacherName: item.teacherName,
    });
  }

  claimRequest(item) {
    if (item["approve"] == undefined) {
      item["claimed"] = this.tokens.username;
    } else {
      var inputVal = document.getElementById("comment").value;
      item["comments"] = inputVal.replace(/[*+\-?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
    }

    var url = new URL(
      "https://odot2imfp3.execute-api.us-east-1.amazonaws.com/apple-runway/claimRequest"
    );
    var request = new XMLHttpRequest();
    request.open("POST", url, false); // `false` makes the request synchronous
    request.setRequestHeader(
      "Authorization",
      "Bearer " + this.tokens.api_token
    );
    request.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    request.send(JSON.stringify(item));

    var json = JSON.parse(request.responseText);
    if (json["message"] == "Internal Server Error") {
      return "Unable to Run Lambda Accurately";
    }
    this.setState({
      show: false,
      showReview: false,
      showApproved: false,
      submitDisabled: true,
    });
  }

  handleChangeText() {
    // separate handler for each field
    var inputVal = document.getElementById("comment").value;
    let textValid = inputVal ? true : false; // basic text validation
    inputVal = inputVal.replace(/[*+\-?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string

    if (textValid) {
      this.setState({ submitDisabled: false });
    }
  }

  render() {
    if (!this.tokens) {
      return (
        <Jumbotron
          style={{
            backgroundColor: "#ECEFF1",
            paddingBottom: "0px",
            minHeight: "100vh",
          }}
        >
          <h1>Session Expired Please Re-Login</h1>
          <NavLink to="/auditors">
            <Button variant="primary" style={{ backgroundColor: "#0091EA" }}>
              Log in or Sign up
            </Button>
          </NavLink>
        </Jumbotron>
      );
    }
    if (!this.tokens.group.includes("auditors")) {
      return (
        <Jumbotron
          style={{
            backgroundColor: "#ECEFF1",
            paddingBottom: "0px",
            minHeight: "100vh",
          }}
        >
          <h1>You don't have access to this page :/</h1>
          <NavLink to="/Home">
            <Button variant="primary" style={{ backgroundColor: "#0091EA" }}>
              Log in or Sign up
            </Button>
          </NavLink>
        </Jumbotron>
      );
    }

    var allRequests = this.get_teacher_requests();

    var pendingRequests = allRequests[1];
    var newRequests = allRequests[0];
    var completedRequests = allRequests[2];

    var dashboardRequests = allRequests[3];

    //console.log(pendingRequests)
    var retrievedPendingResult = [
      {
        Items: ["pencils"],
        cost: 15,
        teacherEmail: "temporary-email@email.com",
        teacherName: "hard-coded name 1",
        approval: "PENDING",
        reasoning: "I want this because I need it ",
        requestID: 2,
      },
      {
        Items: ["binders"],
        cost: 10,
        teacherEmail: "temporary-email@email.com",
        teacherName: "hard-coded name 2",
        approval: "PENDING",
        reasoning: "I want this because I need it ",
        requestID: 1,
      },
    ];
    var retrievedNewResult = [
      {
        Items: ["pencils"],
        cost: 15,
        teacherEmail: "temporary-email@email.com",
        teacherName: "hard-coded name 1",
        approval: "PENDING",
        reasoning: "I want this because I need it ",
        requestID: 2,
      },
      {
        Items: ["binders"],
        cost: 10,
        teacherEmail: "temporary-email@email.com",
        teacherName: "hard-coded name 2",
        approval: "PENDING",
        reasoning: "I want this because I need it ",
        requestID: 1,
      },
    ];
    var retrievedCompletedResult = [
      {
        Items: ["pencils"],
        cost: 15,
        teacherEmail: "temporary-email@email.com",
        teacherName: "hard-coded name 1",
        approval: "PENDING",
        reasoning: "I want this because I need it ",
        requestID: 2,
      },
      {
        Items: ["binders"],
        cost: 10,
        teacherEmail: "temporary-email@email.com",
        teacherName: "hard-coded name 2",
        approval: "PENDING",
        reasoning: "I want this because I need it ",
        requestID: 1,
      },
    ];

    if (pendingRequests !== null) {
      retrievedPendingResult = pendingRequests;
      console.log("pending requests list Recieved");
    }

    if (newRequests !== null) {
      retrievedNewResult = newRequests;
      console.log("pending requests list Recieved");
    }

    if (completedRequests !== null) {
      retrievedCompletedResult = completedRequests;
      console.log("pending requests list Recieved");
    }

    const handleClose = () =>
      this.setState({
        show: false,
        showReview: false,
        showApproved: false,
        submitDisabled: true,
      });

    return (
      <Container
        fluid
        style={{
          backgroundColor: "#ECEFF1",
          paddingLeft: "10%",
          paddingRight: "10%",
        }}
      >
        <Modal show={this.state.show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Request: {this.state.requestId}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ textAlign: "center" }}>
            <h1>{this.state.cost}</h1>
            <h3>{this.state.status} </h3>
            <Table>
              <tr>
                <td>{this.state.teacherName}</td>
                <td>{this.state.teacherEmail}</td>
              </tr>
            </Table>
            <h5>{this.state.reason}</h5>
            <Table striped bordered hover responsive>
              <thead style={{ textAlign: "center" }}>
                <th>Item</th>
                <th>Quantity</th>
                <th>Cost</th>
              </thead>
              <tbody style={{ textAlign: "center" }}>
                {this.state.items.map((item) => (
                  //render name, quant, dollars
                  <tr>
                    <td>{item.name}</td>
                    <td>{item.quant}</td>
                    <td>{item.dollars}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() =>
                this.claimRequest({
                  requestNumber: this.state.requestId,
                  emailAddress: this.state.teacherEmail,
                })
              }
            >
              Review
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showReview} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Request: {this.state.requestId}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ textAlign: "center" }}>
            <h1>{this.state.cost}</h1>
            <h3>{this.state.status} </h3>
            <Table>
              <tr>
                <td>{this.state.teacherName}</td>
                <td>{this.state.teacherEmail}</td>
              </tr>
            </Table>
            <h5>{this.state.reason}</h5>
            <Table striped bordered hover responsive>
              <thead style={{ textAlign: "center" }}>
                <th>Item</th>
                <th>Quantity</th>
                <th>Cost</th>
              </thead>
              <tbody style={{ textAlign: "center" }}>
                {this.state.items.map((item) => (
                  //render name, quant, dollars
                  <tr>
                    <td>{item.name}</td>
                    <td>{item.quant}</td>
                    <td>{item.dollars}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <label for="comment">Comments</label>
            <input id="comment" />
            <button id="commentButton" onClick={() => this.handleChangeText()}>
              Validate Comments
            </button>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="secondary"
              disabled={this.state.submitDisabled}
              onClick={() =>
                this.claimRequest({
                  requestNumber: this.state.requestId,
                  approve: "DECLINED",
                  emailAddress: this.state.teacherEmail,
                })
              }
            >
              Decline
            </Button>
            <Button
              variant="secondary"
              disabled={this.state.submitDisabled}
              onClick={() =>
                this.claimRequest({
                  requestNumber: this.state.requestId,
                  approve: "APPROVED",
                  emailAddress: this.state.teacherEmail,
                })
              }
            >
              Approve
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showApproved} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Request: {this.state.requestId}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ textAlign: "center" }}>
            <h1>{this.state.cost}</h1>
            <h3>{this.state.status} </h3>
            <Table>
              <tr>
                <td>{this.state.teacherName}</td>
                <td>{this.state.teacherEmail}</td>
              </tr>
            </Table>
            <h5>{this.state.reason}</h5>
            <Table striped bordered hover responsive>
              <thead style={{ textAlign: "center" }}>
                <th>Item</th>
                <th>Quantity</th>
                <th>Cost</th>
              </thead>
              <tbody style={{ textAlign: "center" }}>
                {this.state.items.map((item) => (
                  //render name, quant, dollars
                  <tr>
                    <td>{item.name}</td>
                    <td>{item.quant}</td>
                    <td>{item.dollars}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Row md={3}>
          <Col>
            <Card style={{ width: "100%" }}>
              <Card.Header>
                <Card.Title style={{ alignSelf: "center" }}>Pending</Card.Title>
              </Card.Header>
              <Card.Body
                className="overflow-auto"
                style={{ maxHeight: "700px", scrollbarWidth: "thin" }}
              >
                {retrievedPendingResult.map((review) => (
                  <Card>
                    <Card.Header
                      style={{ backgroundColor: "#F44336" }}
                    ></Card.Header>
                    <Card.Body>
                      <Card.Title>{review.teacherName}</Card.Title>
                      <Card.Subtitle className="mb-2 text-muted">
                        {review.school}
                      </Card.Subtitle>
                      <Card.Subtitle className="mb-2 text-muted">
                        <Row>
                          <Col>Status: {review.approval}</Col>
                          <Col>ID: {review.requestID}</Col>
                        </Row>
                      </Card.Subtitle>
                      <Card.Text>{review.reasoning}</Card.Text>
                      <Card.Link
                        href="#"
                        onClick={() => this.openModalWithItem(review)}
                      >
                        View Request
                      </Card.Link>
                    </Card.Body>
                  </Card>
                ))}
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card style={{ width: "100%" }}>
              <Card.Header>
                <Card.Title style={{ alignSelf: "center" }}>
                  Under Review
                </Card.Title>
              </Card.Header>
              <Card.Body
                className="overflow-auto"
                style={{ maxHeight: "700px", scrollbarWidth: "thin" }}
              >
                {retrievedNewResult.map((review) => (
                  <Card>
                    <Card.Header
                      style={{ backgroundColor: "#03A9F4" }}
                    ></Card.Header>
                    <Card.Body>
                      <Card.Title>{review.teacherName}</Card.Title>
                      <Card.Subtitle className="mb-2 text-muted">
                        {review.school}
                      </Card.Subtitle>
                      <Card.Subtitle className="mb-2 text-muted">
                        <Row>
                          <Col>Status: {review.approval}</Col>
                          <Col>ID: {review.requestID}</Col>
                        </Row>
                      </Card.Subtitle>
                      <Card.Text>{review.reasoning}</Card.Text>
                      <Card.Link
                        href="#"
                        onClick={() => this.openReviewModalWithItem(review)}
                      >
                        View Request
                      </Card.Link>
                    </Card.Body>
                  </Card>
                ))}
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card style={{ width: "100%" }}>
              <Card.Header>
                <Card.Title style={{ alignSelf: "center" }}>
                  Completed
                </Card.Title>
              </Card.Header>
              <Card.Body
                className="overflow-auto"
                style={{ maxHeight: "700px", scrollbarWidth: "thin" }}
              >
                {retrievedCompletedResult.map((review) => (
                  <Card>
                    <Card.Header
                      style={{ backgroundColor: "#4CAF50" }}
                    ></Card.Header>
                    <Card.Body>
                      <Card.Title>{review.teacherName}</Card.Title>
                      <Card.Subtitle className="mb-2 text-muted">
                        {review.school}
                      </Card.Subtitle>
                      <Card.Subtitle className="mb-2 text-muted">
                        <Row>
                          <Col>Status: {review.approval}</Col>
                          <Col>ID: {review.requestID}</Col>
                        </Row>
                      </Card.Subtitle>
                      <Card.Text>{review.reasoning}</Card.Text>
                      <Card.Link
                        href="#"
                        onClick={() => this.openCompleteModalWithItem(review)}
                      >
                        View Request
                      </Card.Link>
                    </Card.Body>
                  </Card>
                ))}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Card>
            <Card.Header>All Requests</Card.Header>
            <Card.Body>
            <Row md={3}>
              {dashboardRequests.map((review) => (
                <Col>
                  <Card>
                    <Card.Header
                      style={{ backgroundColor: "#4CAF50" }}
                    ></Card.Header>
                    <Card.Body>
                      <Card.Title>{review.teacherName}</Card.Title>
                      <Card.Subtitle className="mb-2 text-muted">
                        {review.school}
                      </Card.Subtitle>
                      <Card.Subtitle className="mb-2 text-muted">
                        <Row>
                          <Col>Cost: {review.cost}</Col>
                          <Col>Status: {review.approval}</Col>
                          <Col>ID: {review.requestID}</Col>
                        </Row>
                      </Card.Subtitle>
                      <Card.Text>{review.reasoning}</Card.Text>
                      <Table striped bordered hover responsive>
                        <thead style={{ textAlign: "center" }}>
                          <th>Item</th>
                          <th>Quantity</th>
                          <th>Cost</th>
                        </thead>
                        <tbody style={{ textAlign: "center" }}>
                          {review.Items.map((item) => (
                            //render name, quant, dollars
                            <tr>
                              <td>{item.name}</td>
                              <td>{item.quant}</td>
                              <td>{item.dollars}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
              </Row>
            </Card.Body>
          </Card>
        </Row>
      </Container>
    );
  }
}

export default auditors;
