import { Input } from 'aws-amplify-react/lib-esm/AmplifyTheme';
import React, { Component } from 'react';
import { Form, Button, Container, Row, Card, Col, FormLabel, Spinner } from 'react-bootstrap';

class teacherForm extends Component {

   constructor(props) {
      super(props);
      this.state = {
         teacherInfo: [props.name, props.email, props.token],
         items: [{ name: "", quant: "", dollars: "" }],
         itemsValidation: [{ name: "", quant: "", dollars: "" }],
         reason: "",
         reasonValidate: false,
         submitDisabled: true,
         spinnerState: "none",
         submitState: "block"
      }
      this.handleSubmit = this.handleSubmit.bind(this)
      this.handleChange = this.handleChange.bind(this)
      this.handleReason = this.handleReason.bind(this)
   }

   addItem = (e) => {
      this.setState((prevState) => ({
         items: [...prevState.items, { itemName: "", quant: "", dollars: "" }],
         itemsValidation: [...prevState.itemsValidation, { name: "", quant: "", dollars: "" }]
      }))
   }

   removeItem = (e) => {
      const items = this.state.items
      const itemsValidation = this.state.itemsValidation
      if (this.state.items.length != 1) {
         items.splice(items.size, 1)
         itemsValidation.splice(itemsValidation.size, 1)
         this.setState({ items, itemsValidation })
         /*this.setState((prevState) => ({
            items: [...prevState.items, { itemName: "", quant: "", dollars: "" }],
            itemsValidation: [...prevState.itemsValidation, { name: false, quant: false, dollars: false }]
         }))*/
      }

   }

   handleChange = (e) => {
      let items = [...this.state.items]
      let itemsValidation = [...this.state.itemsValidation]
      items[e.target.id][e.target.name] = e.target.value
      if (e.target.name === "name") {
         let decision = false
         let letters = /^[A-Za-z\s]+$/


         if (!e.target.value.match(letters)) {
            decision = true
         }
         if (e.target.value.match(letters)) {
            decision = false
         }
         itemsValidation[e.target.id][e.target.name] = decision
      }
      if (e.target.name === "quant") {
         let decision = false
         let goodnumbers = /^[0-9]+$/
         if ((!e.target.value.match(goodnumbers)) || (e.target.value === "")) {
            decision = true
         }
         if (e.target.value.match(goodnumbers)) {
            decision = false
         }
         itemsValidation[e.target.id][e.target.name] = decision
      }
      if (e.target.name === "dollars") {
         let decision = false

         let goodnumbers = /^[0-9]+$/
         if (!e.target.value.match(goodnumbers)) {
            decision = true
         }
         if (e.target.value.match(goodnumbers)) {
            decision = false
         }
         itemsValidation[e.target.id][e.target.name] = decision
      }

      this.setState({ items, itemsValidation })
      console.log(itemsValidation)

      let anyValue = false
      itemsValidation.forEach(element => {
         if (element.dollars === true || element.name === true || element.quant === true) {
            anyValue = true
         }
      });

      if (anyValue || (this.state.reasonValidate === true)) {
         this.setState({ submitDisabled: true })
      }
      if (!anyValue && (this.state.reasonValidate === false)) {
         this.setState({ submitDisabled: false })
      }
   }

   handleReason = (e) => {
      let reason = e.target.value
      let decision = false
      //let letters = /^[a-zA-Z0-9,.!? ]+$/
      let letters = /^[a-zA-Z0-9$',/.\s\\-]+$/

      if (!e.target.value.match(letters)) {
         decision = true
      }
      if (e.target.value.match(letters)) {
         decision = false
      }
      let reasonValidate = decision

      this.setState({ reason, reasonValidate })

      let anyValue = false
      this.state.itemsValidation.forEach(element => {
         if (element.dollars === true || element.name === true || element.quant === true) {
            anyValue = true
         }
      });

      if (anyValue || (decision === true)) {
         this.setState({ submitDisabled: true })
      }
      if (!anyValue && (decision === false)) {
         this.setState({ submitDisabled: false })
      }

   }

   handleSubmit = (e) => {




      e.preventDefault()

      if (!this.state.submitDisabled) {
         let totalCost = 0
         //new Date().toISOString().slice(0, 10)
         let submitDate = new Date().toISOString()

         this.state.items.forEach(element => {
            totalCost += parseInt(element.dollars)
         });

         let consoleItems = this.state.items
         let consoleReason = this.state.reason

         //submit date
         var jsonBlob = { teacherName: this.state.teacherInfo[0], cost: totalCost, Items: consoleItems, teacherEmail: this.state.teacherInfo[1], reason: consoleReason, Date: submitDate }

         var url = new URL('https://odot2imfp3.execute-api.us-east-1.amazonaws.com/apple-runway/createTeacherRequests')
         var request = new XMLHttpRequest();
         request.open('POST', url, false);  // `false` makes the request synchronous
         request.setRequestHeader("Authorization", "Bearer " + this.state.teacherInfo[2])
         request.setRequestHeader("Content-Type", "application/json;charset=UTF-8")
         request.send(JSON.stringify(jsonBlob));


         this.setState({ submitState: "none" })
         this.setState({ spinnerState: "block" })

         var json = JSON.parse(request.responseText)

         if (json['message'] == "Internal Server Error") {
            return "Unable to Run Lambda Accurately"
         }

         this.props.onSubmit(json)
         this.setState({
            items: [{ name: "", quant: "", dollars: "" }],
            reason: ""
         })

         this.setState({ spinnerState: "none" })
         this.setState({ submitState: "block" })
      }


   }






   render() {
      let { items } = this.state
      return (


         <Container>
            <Form>
               {
                  items.map((val, idx) => {
                     let nameId = `Item-${idx}`, quantId = `quant-${idx}`, dollarsId = `dollars-${idx}`
                     return (
                        <div >
                           <Row>

                              <div key={idx}>
                                 <Form.Group as={Col} controlId={idx} >
                                    <FormLabel>Item Name</FormLabel>
                                    <Form.Control id={idx} value={this.state.items[idx].name} name="name" isInvalid={this.state.itemsValidation[idx].name} onChange={this.handleChange} size="sm" type="text" placeholder="" />
                                    <Form.Control.Feedback type="invalid">Enter only letters</Form.Control.Feedback>
                                 </Form.Group>
                              </div>

                              <div key={idx}>
                                 <Form.Group as={Col} controlId={idx}>
                                    <FormLabel>Estimated Cost</FormLabel>

                                    <Form.Control id={idx} name="dollars" value={this.state.items[idx].dollars} isInvalid={this.state.itemsValidation[idx].dollars} onChange={this.handleChange} size="sm" type="text" placeholder="$" />
                                    <Form.Control.Feedback type="invalid">Enter only whole numbers</Form.Control.Feedback>
                                 </Form.Group>
                              </div>

                              <div key={idx}>
                                 <Form.Group as={Col} controlId={idx}>
                                    <FormLabel>Quantity</FormLabel>
                                    <Form.Control id={idx} name="quant" value={this.state.items[idx].quant} isInvalid={this.state.itemsValidation[idx].quant} onChange={this.handleChange} size="sm" type="text" placeholder="" />
                                    <Form.Control.Feedback type="invalid">Enter only whole numbers</Form.Control.Feedback>
                                 </Form.Group>
                              </div>

                              <div skey={idx}>
                                 <Form.Group as={Col} controlId={idx}>
                                    <Row></Row>
                                    <Button variant="secondary" onClick={this.removeItem}>
                                       <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                          <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                                       </svg>
                                    </Button>
                                 </Form.Group>
                              </div>

                           </Row>
                        </div>
                     )
                  })
               }
               <Form.Group>
                  <Row>
                     <Col>
                        <Button variant="secondary" onClick={this.addItem}>Add an Item</Button>
                     </Col>
                  </Row>
                  {/*
                  <Row>
                     <Col>
                        <Form.Control name="notes" value={this.state.reason} isInvalid={this.state.reasonValidate} onChange={this.handleReason} size="sm" type="text" placeholder="Have Examples? Share the links with us here!" />
                        <Form.Control.Feedback type="invalid">enter valid text</Form.Control.Feedback>
                     </Col>
                  </Row>
                  */}
                  <Row>
                     <Col>
                        <Form.Control name="reason" value={this.state.reason} isInvalid={this.state.reasonValidate} onChange={this.handleReason} size="sm" type="text" placeholder="Tell us how this request will help in your classroom" />
                        <Form.Control.Feedback type="invalid">enter valid text</Form.Control.Feedback>
                     </Col>
                  </Row>
               </Form.Group>
               <Row>
                  <Col style={{ textAlign: 'center' }}>
                     <Button type='submit' onClick={this.handleSubmit}>
                        <Spinner id="submitSpinner" animation="border" variant="light" style={{ display: this.state.spinnerState }} />
                        <p style={{ display: this.state.submitState }} id="submitButton">Submit</p>
                     </Button>
                     {/*<Button type='submit' disabled={this.state.submitDisabled} onClick={this.handleSubmit}>Submit</Button>*/}
                  </Col>
               </Row>

            </Form>
         </Container >

      )

   }
}

export default teacherForm

